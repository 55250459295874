import React from 'react'
import { withTranslation } from 'react-i18next'
import './services.scss'
import { Link } from "gatsby"
import Breakpoint, { BreakpointProvider } from "react-socks"

class Services extends React.Component {

    SERVICE_FADE_TIMEOUT = 5000

    services = []
    interval = null

    state = {
        activeTabIndex: 0,
        activeButtonIndex: null
    }

    componentDidMount() {
        this.interval = setInterval(() => {
            let tabIndex = this.state.activeTabIndex + 1 
            if (tabIndex == this.services.length) {
                tabIndex = 0;
            }
            this.setState({activeTabIndex: tabIndex})
        }, this.SERVICE_FADE_TIMEOUT)
    }
    
    onTabChange = (index) => {
        this.setState({activeTabIndex: index, activeButtonIndex: index})
        clearInterval(this.interval)
    }

    render() {

        const { t } = this.props
        this.services  = this.props.services
        const FADE_DELAY = 200

        return (    
            <div className="our-awesome-services pb-100 pt-100">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="section-title text-center" data-aos="fade-up" data-aos-offset="-200">
                                <span>The hotel <strong>unforgettable</strong></span>
                                <h3>{t('services')}</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center no-gutters service-bg-color">
                        <div className="col-lg-7">
                            <div className="tab-content" id="myTabContent" data-aos="fade-right" data-aos-offset="-300">
                                {this.services.map(({node: item}, index) => (
                                <div className={"tab-pane fade" + (this.state.activeTabIndex === index ? " active show" : "")} id={"service_" + index} role="tabpanel">
                                    <div className="service-img">
                                        <img sizes={item.frontmatter.image.childImageSharp.fluid.sizes} alt={item.frontmatter.alt + "image"} srcset={item.frontmatter.image.childImageSharp.fluid.srcSet} src={item.frontmatter.image.childImageSharp.fluid.src} className="clip_service" />
                                    </div>
                                    <div className="mask" />
                                </div>    
                                ))}
                            </div>
                        </div>
                        <div className="col-lg-5 service-bg">
                            <div className="service-menu-area">
                                <ul className="nav">
                                {this.services.map(({node: item}, index) => (
                                    <li data-aos="fade-left" data-aos-delay={(index + 1) * FADE_DELAY} data-aos-offset="-200">
                                        <div className={"icon-container" + (this.state.activeTabIndex === index ? " active show" : "")} data-toggle="tab" onClick={() => this.onTabChange(index)}>
                                            <span className="large-up service-icon">
                                                <img src={item.frontmatter.icon.childImageSharp.fixed.src} alt={item.frontmatter.alt + "icon"}/>
                                                <Link className={"service-link" + (this.state.activeButtonIndex === index ? "" : " hidden")} to={item.frontmatter.link}>
                                                    {t('details')}
                                                </Link>                                           
                                            </span>
                                            <span className="medium-down service-icon">
                                                <Link to={item.frontmatter.link}>
                                                    <img src={item.frontmatter.icon.childImageSharp.fixed.src} />
                                                </Link>                                           
                                            </span>
                                            <span className="service-title">{item.frontmatter.title}</span>
                                            <span className="text" dangerouslySetInnerHTML={{__html: item.html }}/>
                                            <Link className="read-more d-sm-block d-lg-none pull-right" to={item.frontmatter.link}>
                                                [ {t('details')} ]
                                            </Link>
                                        </div>
                                    </li>
                                ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(Services)