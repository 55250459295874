// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-birthday-js": () => import("./../../../src/templates/birthday.js" /* webpackChunkName: "component---src-templates-birthday-js" */),
  "component---src-templates-carrier-deputy-chef-js": () => import("./../../../src/templates/carrier-deputy-chef.js" /* webpackChunkName: "component---src-templates-carrier-deputy-chef-js" */),
  "component---src-templates-carrier-event-manager-js": () => import("./../../../src/templates/carrier-event-manager.js" /* webpackChunkName: "component---src-templates-carrier-event-manager-js" */),
  "component---src-templates-carrier-front-office-manager-js": () => import("./../../../src/templates/carrier-front-office-manager.js" /* webpackChunkName: "component---src-templates-carrier-front-office-manager-js" */),
  "component---src-templates-carrier-janitor-js": () => import("./../../../src/templates/carrier-janitor.js" /* webpackChunkName: "component---src-templates-carrier-janitor-js" */),
  "component---src-templates-carrier-kitchen-worker-js": () => import("./../../../src/templates/carrier-kitchen-worker.js" /* webpackChunkName: "component---src-templates-carrier-kitchen-worker-js" */),
  "component---src-templates-carrier-maid-js": () => import("./../../../src/templates/carrier-maid.js" /* webpackChunkName: "component---src-templates-carrier-maid-js" */),
  "component---src-templates-carrier-receptionist-js": () => import("./../../../src/templates/carrier-receptionist.js" /* webpackChunkName: "component---src-templates-carrier-receptionist-js" */),
  "component---src-templates-carrier-waiter-js": () => import("./../../../src/templates/carrier-waiter.js" /* webpackChunkName: "component---src-templates-carrier-waiter-js" */),
  "component---src-templates-conference-js": () => import("./../../../src/templates/conference.js" /* webpackChunkName: "component---src-templates-conference-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-drinks-js": () => import("./../../../src/templates/drinks.js" /* webpackChunkName: "component---src-templates-drinks-js" */),
  "component---src-templates-etalon-js": () => import("./../../../src/templates/etalon.js" /* webpackChunkName: "component---src-templates-etalon-js" */),
  "component---src-templates-event-js": () => import("./../../../src/templates/event.js" /* webpackChunkName: "component---src-templates-event-js" */),
  "component---src-templates-events-js": () => import("./../../../src/templates/events.js" /* webpackChunkName: "component---src-templates-events-js" */),
  "component---src-templates-familyday-js": () => import("./../../../src/templates/familyday.js" /* webpackChunkName: "component---src-templates-familyday-js" */),
  "component---src-templates-game-js": () => import("./../../../src/templates/game.js" /* webpackChunkName: "component---src-templates-game-js" */),
  "component---src-templates-gdpr-js": () => import("./../../../src/templates/gdpr.js" /* webpackChunkName: "component---src-templates-gdpr-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-home-office-js": () => import("./../../../src/templates/home_office.js" /* webpackChunkName: "component---src-templates-home-office-js" */),
  "component---src-templates-kidparty-js": () => import("./../../../src/templates/kidparty.js" /* webpackChunkName: "component---src-templates-kidparty-js" */),
  "component---src-templates-luxury-car-js": () => import("./../../../src/templates/luxury-car.js" /* webpackChunkName: "component---src-templates-luxury-car-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-news-list-js": () => import("./../../../src/templates/newsList.js" /* webpackChunkName: "component---src-templates-news-list-js" */),
  "component---src-templates-room-js": () => import("./../../../src/templates/room.js" /* webpackChunkName: "component---src-templates-room-js" */),
  "component---src-templates-rooms-js": () => import("./../../../src/templates/rooms.js" /* webpackChunkName: "component---src-templates-rooms-js" */),
  "component---src-templates-rules-js": () => import("./../../../src/templates/rules.js" /* webpackChunkName: "component---src-templates-rules-js" */),
  "component---src-templates-sportsrental-js": () => import("./../../../src/templates/sportsrental.js" /* webpackChunkName: "component---src-templates-sportsrental-js" */),
  "component---src-templates-tender-js": () => import("./../../../src/templates/tender.js" /* webpackChunkName: "component---src-templates-tender-js" */),
  "component---src-templates-wedding-js": () => import("./../../../src/templates/wedding.js" /* webpackChunkName: "component---src-templates-wedding-js" */),
  "component---src-templates-wellness-js": () => import("./../../../src/templates/wellness.js" /* webpackChunkName: "component---src-templates-wellness-js" */)
}

